<template>
  <div class="company-view-page container" v-if="company">
    <h1>Edit company</h1>
    <hr />

    <form>
      <div class="mb-3">
        <label for="name" class="form-label">Name</label>
        <input
          type="text"
          class="form-control"
          id="name"
          v-model="name"
          :disabled="!currentAccount.hasPermission('admin.companies.edit')"
        />
      </div>

      <button type="button" class="btn btn-primary" :disabled="!currentAccount.hasPermission('admin.companies.edit')" @click="save">
        Save
      </button>
      <button type="button" class="btn btn-danger ms-2" :disabled="!currentAccount.hasPermission('admin.companies.remove')" @click="remove">
        Delete
      </button>

      <span class="ms-3 text-muted">
        <template v-if="company.updatedAt">
          Updated by <b>{{ company.updatedByAccount?.login ?? 'Unknown' }}</b> at
          <b>{{ dateTimeFormatter.format(new Date(company.updatedAt)) }}</b>
        </template>
        <template v-else>
          Created by <b class="font-weight-bold">{{ company.createdByAccount?.login ?? 'Unknown' }}</b> at
          <b>{{ dateTimeFormatter.format(new Date(company.createdAt)) }}</b>
        </template>
      </span>
    </form>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { dateTimeFormatter } from '@/utils';
import { CompanyStatus, CompanyLang } from 'fifty-seven-llc-common';
import { findCompany, updateCompany, deleteCompany } from '@/api';

export default {
  name: 'CompanyEditPage',
  computed: {
    ...mapGetters(['account/current']),
    currentAccount() {
      return this['account/current'];
    },
  },
  data: () => ({
    name: '',

    company: null,

    dateTimeFormatter,
  }),
  methods: {
    async update(withReset = true) {
      try {
        if (withReset) {
          this.company = null;
        }

        const id = this.$route.params.id;
        if (!id) {
          return;
        }

        const response = await findCompany(id);
        if (response?.errors) {
          throw new Error(response.errors[0].message);
        }

        this.company = response?.data?.company;
        if (!this.company) {
          throw new Error('Company not found');
        }

        this.name = this.company.name;
      } catch (error) {
        console.error(error);
        this.$toast.error(error.message);
      }
    },

    async save() {
      try {
        const data = {};

        if (this.name && this.name !== this.company.name) {
          data.name = this.name;
        }

        const response = await updateCompany(this.$route.params.id, data);
        if (response.errors) {
          throw new Error(response.errors[0].message);
        }

        this.$toast.success('Company was updated.', { duration: 2000 });
        setTimeout(() => this.$router.push({ name: 'companies' }), 2000);
        this.update(false);
      } catch (err) {
        console.error(err);
        this.$toast.error(err.message);
      }
    },

    async remove() {
      try {
        const response = await deleteCompany(this.$route.params.id);
        if (response.errors) {
          throw new Error(response.errors[0].message);
        }

        this.$router.push({ name: 'companies' });
        this.$toast.success('Company was deleted.', { duration: 2000 });
      } catch (err) {
        this.$toast.error(err.message);
      }
    },
  },
  activated() {
    this.update();
  },
  watch: {
    '$route.params.id': {
      handler() {
        this.update();
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>

<style scoped>
.company-view-page {
  padding: 2em 0;
}

.control input {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.control button {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.home-location label {
  display: block;
}

.home-location input {
  width: auto;
  display: inline-block;
}

.home-location button {
  vertical-align: top;
}
</style>
